.table-33 {
  width: 33%;
}
.table-66 {
  width: 66%;
}
.text-highlight {
  text-shadow: 2px 2px 4px #ffc107;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: 2px;
}
