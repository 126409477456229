body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ecf1f8 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiOutlinedInput-root {
  max-height: 40px;
}
#fase_type {
  top: 17px;
  margin: 0px 10px;
}
#fase_type label {
  top: -6px;
}
table th,
table td {
  white-space: nowrap;
}
#quote-list th,
#quote-list td {
  max-width: 10vw;
  overflow: hidden;
}

.quote-input-center {
  text-align: center;
}
.quote-input-right {
  text-align: right;
}
.quote-input {
  width: 100%;
  background-color: transparent;
  color: #343869;
  font-size: 1.3vw;
}
.quote-input:valid {
  border: none !important;
  /* text-align: center; */
  width: 100%;
}
.quote-button {
  background-color: #ffc710 !important;
  color: #343869 !important;
  margin-left: 20px;
  font-weight: bold !important;
}

.App {
  padding: 0px 0%;
}
input {
  border: #ffc710;
}

text {
  font-size: 1.4vw !important;
}
.modal-content {
  border: none !important;
}
/* FOOTER ICONS */
.instagram {
  width: 40px;
  padding: 4px;
}
.facebook {
  width: 35px;
  padding: 4px;
}
.web {
  width: 40px;
  padding: 4px;
}
.antes {
  float: left;
  position: relative;
  z-index: 1;
  left: 27%;
  margin-top: 1%;
  font-weight: bold;
}
.depois {
  float: left;
  position: relative;
  z-index: 1;
  left: 58%;
  margin-top: 8.5%;
  font-weight: bold;
}
.btn-success {
  background-color: rgb(253, 193, 16) !important;
  color: rgb(52, 56, 105) !important;
  font-weight: bold !important;
}
.paint-red {
  background-color: red;
  padding: 10px 6px;
  margin: -6px;
}
@media print {
  .modal {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    overflow: visible !important;
    overflow-y: visible !important;
  }
  .modal-backdrop {
    background-color: white !important;
  }
  .modal-open .modal {
    position: absolute;
  }
  .App {
    display: none;
  }
  table {
    overflow: hidden;
  }
  body {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  .pagebreak {
    page-break-before: always;
  }
  .quotechart {
    padding-left: 50px !important;
  }
  .antes {
    left: 29%;
  }
  .depois {
    left: 66%;
    margin-top: 9.5%;
  }
}
