/* Styles go here */

.page-header,
.page-header-space {
  height: 144px;
  left: 0px;
  padding: 0px 20px;
}

.page-footer,
.page-footer-space {
  height: 175px;
  left: 0px;
  padding: 0px 20px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
}

.page {
  page-break-after: always;
  page-break-inside: avoid;
}

@page {
  margin: 20mm;
}

@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}

.printable {
  display: none;
}
@media print {
  .non-printable {
    display: none !important;
  }
  .printable {
    display: block;
  }
  .modal-dialog {
    min-width: 100% !important;
    top: -124px;
  }
}
